const translations = {
  bio: {
    title: "Hi, I'm {name}"
  },
  body: "Need to follow up on your existing plan or discuss something new? Let's get in touch.",
  cta: "Schedule a session",
  helper: "Care Partner bio",
  title: "We're here when you need us"
};

export default translations;
