/* eslint-disable @typescript-eslint/naming-convention */

const translations = {
  tasks: {
    status: {
      completed: {
        description: "Resources curated for you —",
        label: "Ready"
      },
      default: {
        description: "Care Partner resources"
      },
      in_progress: {
        description: "Sit tight! We're updating these resources —",
        label: "Open",
        tooltip: "You will get an email when your Care Plan is updated"
      },
      updating: {
        description: "Sit tight! We're updating these resources —",
        label: "Open"
      }
    }
  }
};

export default translations;
