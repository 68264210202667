const translations = {
  asFeaturedIn: "As featured in",
  expertGuidance:
    "Through 1:1 Care Partner guidance and our online, tech-driven platform, we step in to provide you with logistical and emotional support.",
  features: {
    planAhead: {
      body: "When navigating care for a child, adult, or yourself, we help you avoid common pitfalls to see what's ahead. We will compare costs, handle referrals, and help with transitions.",
      heading: "Plan Ahead"
    },
    research: {
      body: "Whether you need a cleaning service, lawyer, doctor, or something else, Grayce can find it. Let us vet and recommend the best services, saving the headache.",
      heading: "Research & Recommend"
    },
    saveTime: {
      body: "We can save you time by arranging rides, scheduling appointments, setting up food delivery services, and much more!",
      heading: "Save Time"
    }
  },
  getPeaceOfMind: "Get peace of mind",
  getStartedDesktop: "Get started with your free benefit",
  getStartedMobile: "Start your free benefit",
  getSupport: "Get the support you deserve today.",
  memberRating: "4.9/5 member rating",
  testimonials: {
    adult: {
      body: "“I needed resources and input on how to navigate my 85 year old mother's living condition, as well as my sister who has a [traumatic brain injury]. My Grayce Care Partner has been EXCEPTIONAL. I love the Grayce service. Grayce has allowed me to navigate a very difficult situation. Prior to Grayce, I was at a point that made it hard to concentrate on work and other family matters. With Grayce, I feel supported and know that help is only a click away. I have recommend Grayce to my co-workers numerous times. Amazing service!!”",
      button: "Care for an adult loved one",
      headline: "Amazing service!!",
      location: "Colorado, USA",
      name: "Grayce member since October 2022"
    },
    aging: {
      body: "“[I] needed help with a cancer scare experienced by my elderly mother. The service is a Godsend - I know I can get expert support with medical challenges and that I'm not ‘alone’ despite being an only child supporting my elderly parents. It's a no brainer to take advantage of a service like Grayce - I've used it twice now and it helps alleviate the stress from having to juggle work as well as my other responsibilities caring for others.”",
      button: "Care for an aging loved one",
      headline: "The service is a Godsend.",
      location: "New Jersey, USA",
      name: "Grayce member since October 2022"
    },
    yourself: {
      body: "“Grayce has helped me feel more in control of my healthcare and my health. My Care Partner has helped me schedule surgery, physical therapy, cardiac clearance, figure out insurance, appeal and submit claims, research doctors that fit my needs and are covered by my plan, and much more! I went from barely even being able to walk due to my illness to being able to dance around my house while listening to music. Grayce helped me get my life back. I'm so grateful to have Grayce and feel in control of my healthcare now.”",
      button: "Care for yourself",
      headline: "Grayce helped me get my life back.",
      location: "Florida, USA",
      name: "Grayce member since November 2022"
    }
  },
  topicPages: {
    Adult: {
      disclaimer:
        "Grayce does not provide legal, financial, real-estate, or medical advice, and we do not intervene in situations where legal mandates and laws around power of attorney apply.",
      focusAreas: {
        financial:
          "We help you identify care funding options considering your income, available government programs, and scholarship opportunities. We also assist in finding financial resources for future savings, and help you select the right insurance provider and plan for your care needs. Our team can also advocate with insurance on your behalf.",
        functional:
          "We can help find care or housing facilities, navigate veterans care, coordinate transportation, and ensure safety by helping you organize emergency contacts, home safety assessments, and evacuation plans. We also provide support in coordinating meals that meet nutritional and logistical needs.",
        legal:
          "We offer support and guidance in planning and reviewing legal documents, as well as assistance in navigating conversations with family members involved in legal affairs.",
        medical:
          "We assist in finding and coordinating medical care, provide assistance with medication affordability and delivery, help with specialists, second opinions, and clinical trials, help prepare for doctor visits, and aid in ordering equipment or supplies.",
        social:
          "We support you in finding local community groups and support networks relevant to your or your loved one's individual needs. We encourage mental engagement by ensuring hobbies and interests are in place, including finding local volunteer opportunities.",
        wellbeing:
          "Self-care is essential when caring for others. We help you prioritize your well-being by offering emotional support, guidance with coping strategies, logistical coordination of individual health needs, and assistance with time management and scheduling."
      },
      holisticExplanation:
        "Our comprehensive services cover all aspects of caring for a loved one or yourself. With your benefit, receive a holistic care plan tailored to your needs.",
      subtitle:
        "Whether you or your partner, sibling, adult child or other loved one is managing a disability or illness, have received a new diagnosis, or are experiencing stressful life events, our comprehensive services can support you across multiple areas of care.",
      title: "Caring for an adult loved one or yourself"
    },
    Aging: {
      disclaimer:
        "Grayce does not provide legal, financial, or medical advice, nor do we offer fertility guidance or support with child custody.",
      focusAreas: {
        financial:
          "We can help identify care funding options, advocate with insurance providers, assist in financial planning for future savings, and help find the right insurance coverage for your needs. We also provide support in determining ways to pay for housing and equipment modifications and offer assistance with navigating Medicare and other related programs.",
        functional:
          "We can help find care or housing facilities, navigate veterans care, ensure accessibility in the home, coordinate transportation needs, coordinate meals that meet specific nutritional and logistical needs, and provide guidance on safety for aging loved ones, including emergency contacts, home safety assessments, evacuation plans, and emergency response options.",
        legal:
          "We can provide guidance in navigating guardianship or conservatorship needs, implementing durable power of attorney, planning and reviewing legal documents, and navigating conversations with family members. We also offer support with end-of-life planning and logistics, ensuring that the wishes of your loved ones are honored.",
        medical:
          "We can offer support in coping with a dementia diagnosis, coordinating medical care, connecting with gerontologists, identifying specialized emergency departments, providing medication assistance, finding specialists and second opinions, assisting with doctor visits, and helping with equipment and supply needs.",
        social:
          "We can find local community groups and support networks, encourage mental engagement through supporting hobbies and interests, and even assist in finding local volunteer opportunities.",
        wellbeing:
          "Self-care is essential when caring for others. We help you prioritize your well-being by offering emotional support, guidance with coping strategies, logistical coordination of individual health needs, and assistance with time management and scheduling."
      },
      holisticExplanation:
        "Our comprehensive services support multiple areas of caring for an elderly loved one. With your benefit, receive a holistic care plan tailored to your needs.",
      subtitle:
        "Whether your parent, grandparent, or other aging loved one is managing dementia or Alzheimer's, is receiving cancer treatment, has a chronic condition, or has received a new medical diagnosis, Grayce can help.",
      title: "Caring for an aging loved one"
    },
    Child: {
      disclaimer:
        "Grayce does not provide legal, financial, real-estate, medical advice, or support with child custody.",
      focusAreas: {
        financial:
          "We help you identify care funding options considering your income, available government programs, and scholarship opportunities. We also assist in finding financial resources for future savings, such as college planning, and help you select the right insurance provider and plan for your needs. Our team can also advocate with insurance on your behalf.",
        functional:
          "We can help you find child care and transportation services, and provide guidance on child safety, including home safety assessments and recommendations, evacuation plans, public safety, internet safety, and water safety. We also offer support with meal planning and logistics.",
        legal:
          "We offer support and guidance in planning and reviewing legal documents related to your children, as well as assistance in navigating conversations with family members involved in legal affairs.",
        medical:
          "We assist in coordinating medical care, identifying specialists and therapists based on your insurance coverage, and providing advice on developmental assessments and recommendations. We can also help you prepare for medical appointments and offer expert guidance on talking to your kids about health-related topics.",
        social:
          "We can support school enrollment, including identifying schooling systems and assisting with applications and waitlists. We can help identify tutoring and after-school education options, guide you through special education and individualized education programs (IEPs), and serve as a disability advocate. We also can connect you with community resources like parenting groups and playgroups.",
        wellbeing:
          "Self-care is essential when caring for others. We help you prioritize your well-being by offering emotional support, guidance with coping strategies, logistical coordination of individual health needs, and assistance with time management and scheduling."
      },
      holisticExplanation:
        "Our comprehensive services cover all aspects of caring for a child. With your benefit, receive a holistic care plan tailored to your needs.",
      subtitle:
        "Whether your child has an illness, a disability, a recent diagnosis, neurodiverse needs, or is entering a new developmental stage, Grayce can support.",
      title: "Caring for a child"
    },
    Grieving: {
      disclaimer: "Grayce does not provide legal, financial, real-estate, or medical advice.",
      focusAreas: {
        financial:
          "We can help notify financial institutions and guide you through the necessary steps to handle your loved one's financial affairs.",
        functional:
          "We provide practical assistance in navigating the next steps after loss by creating a plan. We can help with organizing probate logistics and funeral arrangements, or assist with forms and paperwork. We can also help you navigate challenging or emotional conversations with family and children.",
        social:
          "We offer support in finding local community groups and engagement opportunities to promote your well-being.",
        wellbeing:
          "We offer emotional support every step of the way, helping you process grief and providing guidance on coping strategies. We ensure your individual needs are prioritized, assisting with coordinating your health needs and bereavement leave with your employer.  Our team can assist you in locating mental health resources, such as community support groups and therapists, to help you navigate your healing journey.  We can also assist you in determining meaningful ways to memorialize your loved one, offering support and ideas for commemorating special occasions like birthdays and holidays."
      },
      holisticExplanation:
        "Our comprehensive services support multiple areas of grief and bereavement. With your benefit, receive a holistic care plan tailored to your needs.",
      subtitle:
        "Grayce can help provide care needs beyond the end of life of a loved one. Our bereavement services are designed to provide emotional and logistical support during times of loss.",
      title: "Grieving the loss of a loved one"
    },
    Pregnancy: {
      disclaimer:
        "Grayce does not provide legal, financial, or medical advice, nor do we offer fertility guidance or support with child custody.",
      focusAreas: {
        financial:
          "We can help you identify care funding options considering your income, available government programs, and scholarship opportunities. We can assist in finding financial resources for future savings, such as college planning. We can also help you select the right insurance provider for your needs as well as advocate with insurance on your behalf.",
        functional:
          "We can help you find suitable care or childcare facilities and can provide guidance and assist in the coordination of parental leave and returning to work.",
        legal:
          "We offer support and guidance in planning and reviewing legal documents related to your children, including those for adoptive or non-gestational parents. We can also assist in navigating conversations with family members involved in legal affairs.",
        medical:
          "We can coordinate general and obstetrics care, and connect parents with general and specialist healthcare professionals such as doulas, physical therapists, nutritionists, lactation, and sleep specialists. Our team can also support finding and coordinating pediatric or specialist care and help navigate infant developmental milestones.",
        social:
          "We help you maintain social connections by assisting in finding local community parent groups or educational classes.",
        wellbeing:
          "Self-care is essential when caring for others. We offer emotional support, maternal mental health assistance, coping strategies, and logistical coordination of personal healthcare needs."
      },
      holisticExplanation:
        "Our comprehensive services cover all aspects of prenatal and infant care. With your benefit, receive a holistic care plan tailored to your needs.",
      subtitle:
        "Your Grayce benefit is a centralized source of support to ensure a smooth and empowered journey into parenthood.",
      title: "Pregnancy or caring for an infant"
    },
    activateButton: "Activate your free benefit",
    features: {
      easyAccess: {
        body: "Whether you need a cleaning service, lawyer, doctor, or something else, Grayce can find it. Let us vet and recommend the best services, saving the headache.",
        title: "Easy-access, personalized services"
      },
      expertGuide: {
        body: "When navigating care for a child, adult, or yourself, we help you avoid common pitfalls to see what's ahead. We will compare costs, handle referrals, and help with transitions.",
        title: "An expert guide & Care Partner"
      },
      saveTime: {
        body: "We can save you time by arranging rides, scheduling appointments, setting up food delivery services, and much more!",
        title: "Save time, money, and energy"
      }
    },
    focusAreas: {
      financial: "Financial",
      functional: "Functional",
      legal: "Legal",
      medical: "Medical",
      social: "Social",
      wellbeing: "Your well-being"
    },
    getTheSupport: "Get the support you deserve today.",
    holisticCare: "Holistic care support",
    weLightenTheLoad: "We lighten the load"
  },
  topics: {
    adult: "Caring for an adult or myself",
    aging: "Caring for an aging loved one",
    child: "Caring for a child",
    grieving: "Grieving a loss",
    pregnancy: "Pregnancy or caring for an infant"
  },
  weHelpLightenTheLoad: "We help lighten the load when caring for the people you love",
  weStepIn: "We step in to provide caregiving support when you need it most",
  whatOurMembersAreSaying: "What our members are saying",
  whatSituation: "What situation can we help you with?"
};

export default translations;
