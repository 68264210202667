const faq = {
  section: {
    carePartners: {
      availableWhenNeedHelp: {
        content:
          "Grayce Care Partners respond within one business day, though we strive to provide support as soon as possible.  If your matter is urgent, please note that in your message.",
        header: "What if my Care Partner is not available when I need help?",
        moreContent:
          "If your dedicated Care Partner is out of the office, you will still always have expert support.  During absences, a back-up Care Partner will be provided and equipped to support you."
      },
      communicate: {
        content:
          "Grayce Care Partners are available for video and phone sessions.  Between sessions, your Care Partner is available via messaging in the app.",
        header: "How do I communicate with my Care Partner?"
      },
      emergencies: {
        content:
          "If you are having a medical emergency, please call the local authorities.  For other emergencies, please seek local support.  Grayce support is available during business hours, with responses within one business day.  We do our best to respond to urgent matters as soon as possible.",
        header: "Are Care Partners available for emergencies?"
      },
      header: "Care Partners",
      notHappy: {
        content:
          "Your comfort and satisfaction is important to us.  Contact us at support@withgrayce.com and we'll be glad to help.",
        header: "What if I'm not happy with my Care Partner?"
      },
      otherPerson: {
        content:
          "Yes, you can invite others to join your sessions. See “My Account” section below for more information.",
        header: "Can another person participate in sessions with my Care Partner?"
      },
      whoHelpsMe: {
        content:
          "You will be matched to a dedicated Care Parter, who has years of experience in supporting a broad range of family needs at leading health care organizations. In addition to extensive experience in direct care management and healthcare, many have a Master's degree in Social Work.",
        header: "Who helps me? What are their professional credentials?"
      }
    },
    faq: {
      header: "Grayce FAQ",
      whatIsGrayce: {
        content:
          "Grayce is a digital platform to support you in caring for loved ones, so that you can maintain peace of mind. Through personalized 1:1 expert guidance and our online, tech-driven platform we step in to provide logistical and emotional caregiving support. Grayce helps families navigate needs for their loved ones by providing expert guidance, community support, information, planning, and coordination.",
        header: "What is Grayce?"
      },
      whyDoesGrayceExist: {
        content:
          "Grayce exists because you care.  Caring for loved ones is challenging and complicated.  It can be fulfilling while also feeling overwhelming, confusing, and isolating.  There are not enough resources to guide and support families along the way.  We're here to make the process feel more graceful, to help guide you on your care journey.",
        header: "Why does Grayce exist?"
      }
    },
    myAccount: {
      confidential: {
        content:
          "Yes, your privacy is important to us. Your identity and individual account information is confidential (including confidential from your employer).",
        header: "Is my information confidential?"
      },
      cost: {
        content:
          "If you learned about Grayce through your employer, they subsidize a subscription and a specific number of sessions.  You are welcome to purchase additional support at any time by upgrading your support level.",
        header: "What is the cost to use Grayce?"
      },
      header: "My Account",
      joinAccount: {
        content:
          "Yes, you can invite others to join your account as a participant.  You will remain the primary account holder with control over the account. Once logged into the Grayce app, you can add others via an email invitation in your Grayce Care Plan.",
        header: " Can I invite another person to join my account?  What about the person for whom I'm caring?"
      },
      questions: {
        content: "Please contact us at ",
        header: "What if I have questions or need support with my account?",
        mailTo: "support@withgrayce.com ",
        remainder: "and we'll be glad to help."
      },
      runOutOfSupport: {
        content:
          "You can upgrade your support level for an additional cost. Purchase additional support in your account.",
        header: "What happens if I run out of Care Partner support?"
      },
      signUp: {
        content: "We’re honored to support you. Get started with Grayce today.",
        header: "Where do I sign up for Grayce?",
        link: "Sign up "
      },
      supportingMoreThanOnePerson: {
        content:
          "Grayce can support you with needs for multiple people. When you create an account we'll ask a few questions to understand your needs. Additionally, you can add additional Journeys to your account at any time.",
        header: "What if I'm supporting more than one person?"
      }
    },
    support: {
      differentLocation: {
        content:
          "Grayce is a virtual solution.  We can support you across geographies and logistical circumstances.  If needs arise for which you need additional local support, we'll help support you on identifying local solutions.",
        header: "Does it matter if I am located in a different place than the person about whom I'm seeking support?"
      },
      examples: {
        afterList: {
          intro: "Please visit our ",
          link: "Member Benefit Activation Page ",
          remainder:
            "to further explore our services by selecting from the “What situation can we help you with?” tile menu."
        },
        content:
          "Grayce's comprehensive services provide support for multiple aspects of care no matter the situation. A few common examples include –",
        header: "Can you give me examples?",
        list: {
          challengingConversations:
            "Facilitating challenging conversations surrounding your loved one's care with your loved one or others who also support",
          legalFinancial: "Navigating legal and financial planning for your loved one's care",
          personalWellBeing: "Providing support for your personal well-being",
          planningForNeeds: "Assessing and planning for both current and projected needs of a loved one",
          researchingCareServices:
            "Researching and vetting care services such as in-home care, childcare, back-up care, and more",
          support: "Supporting you and a loved one through the end-of-life stage as well as support with bereavement"
        }
      },
      header: "Support",
      homeCare: {
        content: "Grayce is a virtual support solution.  We can support you to find local home care services.",
        header: "Does Grayce provide home care services?"
      },
      legalFinancial: {
        content: "We can support you to find legal and financial services as needed.",
        header: "Does Grayce provide legal or financial services?"
      },
      ownCareNeeds: {
        content: "Yes, we can help you.",
        header: "I need help with some of my own care needs. Can Grayce help me?"
      },
      process: {
        content:
          "When you sign-up for Grayce we'll guide you through a few questions to help us customize your account based on your needs. At sign-up you are also assigned a dedicated Care Partner to support and guide you throughout your entire Grayce Journey. You can connect with your Care Partner on an initial session via video chat, phone, or messaging. That initial session is time with your Care Partner to get up to speed on your situation, your needs, and goals. Based on your initial session, your Care Partner will prepare a personalized Care Plan for you and support you through tackling the plan, together. As your loved one's circumstances change, we are here for you to touch base, answer questions, and make sure you have what you need.",
        header: "How does Grayce's process work?"
      },
      whatDoesGrayceHelpWith: {
        content:
          "Supporting a loved one can be one of life's most rewarding and challenging roles. Grayce helps you navigate and succeed in this role by offering expert guidance and support, information and education, community connection, and logistical coordination. Whether you're navigating insurance and finances, legal paperwork, daily logistics, medical care, educational needs, or housing, Grayce can support as a trusted guide and helping hand.",
        header: "What does Grayce help with?"
      },
      whoDoesGraceHelpMeCareFor: {
        content:
          "Grayce is here to help. Whether you're helping your parent, grandparent, spouse, partner, sibling, other relative, friend, child, or neighbor, we're here for you.  If they matter to you, they matter to us.",
        header: "Who does Grayce help me to care for?"
      }
    }
  }
};

export default faq;
