import { ComponentProps } from "react";
import { IntlProvider } from "react-intl";

import accessibility from "@i18n/accessibility";
import components from "@i18n/components";
import contact from "@i18n/contact";
import countries from "@i18n/countries";
import dictionary from "@i18n/dictionary";
import errors from "@i18n/errors";
import faq from "@i18n/faq";
import forms from "@i18n/forms";
import languages from "@i18n/languages";
import menus from "@i18n/menus";
import models from "@i18n/models";
import notifications from "@i18n/notifications";
import pages from "@i18n/pages";
import pageTitles from "@i18n/pageTitles";
import referACoworkerAgreement from "@i18n/referACoworkerAgreement";
import timezones from "@i18n/timezones";
import { flatten } from "@utils/mapUtils";

const nestedTranslations = {
  accessibility,
  components,
  contact,
  countries,
  dictionary,
  errors,
  faq,
  forms,
  languages,
  menus,
  models,
  notifications,
  pageTitles,
  pages,
  referACoworkerAgreement,
  timezones
};

const en: NonNullable<ComponentProps<typeof IntlProvider>["messages"]> = flatten(nestedTranslations);

export default en;
