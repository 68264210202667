const translations = {
  heading: {
    inbox: "Messages",
    new: "New message"
  },
  inbox: {
    title: "Inbox"
  }
};

export default translations;
