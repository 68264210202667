const translations = {
  attending: "Attending:",
  body: {
    // default is just here as a typescript fallback that shouldn't ever really happen
    default: "Your session is booked. We'll see you soon!",
    phone: "We'll call you at {number}. If you need to change your number, click below.",
    zoom: "Your video session link will become active here at {time}."
  },
  cta: {
    edit: "Change",
    join: "Join"
  },
  desktopTitleSupplement: "on {date} at {time}",
  mobileTitleSupplement: "Booked",
  title: {
    default: "Your session is",
    next: "Your next session is"
  }
};

export default translations;
