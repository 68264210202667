const translations = {
  groups: {
    seeAllGroups: "See all groups",
    title: "Groups"
  },
  post: {
    cta: "Open in Gather"
  }
};

export default translations;
