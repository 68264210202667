const referACoworkerAgreement = {
  section: {
    additionalDefinedTerms: {
      header: "7. Additional Defined Terms",
      list: {
        memberDefinition:
          "Member or you means the individual who has enrolled in Grayce services and is executing this Referral Program Agreement.",
        notice:
          "Notice means any physical or electronic communication or legal notices related to this Referral Program Agreement that are provided to you through text or SMS, email, your Grayce account, or by other means."
      }
    },
    eligibility: {
      description: 'In order to participate in the Grayce "Refer a Coworker" Program, you must:',
      header: "1. Referral Program Eligibility",
      list: {
        atLeastEighteen:
          "Ensure that all users distributing Referral Links are natural persons of at least 18 years of age.",
        goodStanding: "Be a Grayce member in good standing."
      }
    },
    entireAgreement: {
      description: `This Referral Program Agreement constitutes the entire understanding between the Member and Grayce for the subject matter described, and no other agreements, representations, or warranties other than those provided in this Referral Program Agreement will be binding unless in writing and signed by you and Grayce.`,
      header: "6. Entire Agreement"
    },
    intellectualProperty: {
      description: `Grayce is the owner of all intellectual property related to the Referral Link, including and without limitation the services and related technology; Grayce data; and copyrights, patents, trade secrets, trade or service marks, brands, logos, and other intellectual property incorporated into each of the foregoing (the "Grayce Property"). Grayce grants you a limited, nonexclusive, revocable, and nontransferable license to use Grayce Property as permitted by this Referral Program Agreement.`,
      header: "4. Intellectual Property"
    },
    intro: {
      header: "Refer A Coworker Program Agreement",
      platformAgreement:
        "The Grayce Platform Agreement, as amended from time to time, is incorporated into and forms part of this Referral Program Agreement and shall apply to your participation in the Referral Program. In the event of any inconsistency between any of the provisions of the Platform Agreement and this Referral Program Agreement, this Referral Program Agreement will control. Any capitalized terms that are not defined in this Referral Program Agreement shall have the meaning provided in the Platform Agreement. We may modify this Referral Program Agreement by posting an updated version on our website. By continuing to participate in the Referral Program after the posting of such modifications, you shall be deemed to accept such modifications.",
      programAgreement: `This Referral Program Agreement, in combination with other agreements relating to your use of Grayce services, is made between you ("Member", "your") and Grayce ("we," "our", "us") and governs your participation in the Grayce "Refer a Coworker" Program (the "Referral Program"). For purposes of this Referral Program Agreement, references to Member include any users authorized to participate in the Referral Program on the Member's behalf. Members shall be fully responsible for the acts or omissions of their users. By requesting or sending a Referral Link (defined below), you will be deemed to have accepted and be bound by this Agreement.`
    },
    privacy: {
      description:
        "Any information obtained in connection with the Referral Program will be collected, processed, and used in accordance with {link}.",
      header: "3. Privacy",
      linkText: "Grayce's Privacy Policy"
    },
    qualification: {
      conclusion:
        "The Referral Link should only be provided directly to your personal acquaintances. Additionally, you and your users may not use any paid advertisement to promote the Referral Program, including but not limited to, paid search marketing, advertisements, affiliate networks, paid email campaigns, or other similar methods. You may not use a Referral Link to create a new Grayce account for yourself, your affiliated entities, or another coworker. By participating in the Referral Program, you represent and warrant that (i) each of your users has a personal relationship with his/her referral or otherwise has the necessary consents to submit their information to the Referral Program; and (ii) each of your users will comply with all applicable Grayce policies and terms, and any and all applicable laws or regulations.",
      description:
        'Grayce will provide you with a unique URL that can be provided to prospective Grayce members (the "Referral Link"). A "Qualifying Referral" must meet all of the following conditions:',
      header: "2. Qualifying Referrals",
      list: {
        activationCriteria:
          "The referred coworker meets the activation criteria set by Grayce, which may include specific usage or engagement metrics.",
        notRegistered:
          "The referred coworker is not registered, and was not previously registered, with Grayce under any email address or alias.",
        usesReferralLink:
          "The referred coworker completes the registration process with Grayce using the referrer's Referral Link. If a referred coworker registers with Grayce using any other link or method, the registration will not count as a Qualifying Referral.",
        validatedEmailDomain:
          "The referred coworker is validated through their email domain to ensure they are a coworker. Additionally, the referred coworker is verified as eligible for Grayce based on the employer's Grayce package standards."
      }
    },
    termination: {
      description:
        "Grayce reserves the right to suspend or terminate the Referral Program at any time or to change this Referral Program Agreement at any time and for any reason in our sole discretion upon notice to you or by deactivation of the Referral Link. Your eligibility for the Referral Program and the eligibility of any referrals is determined in the sole discretion of Grayce.",
      header: "5. Termination"
    }
  }
};

export default referACoworkerAgreement;
