/* eslint-disable @typescript-eslint/naming-convention */
const translations = {
  fromCarePlan: "From your Care Plan",
  heading: "Notifications",
  kinds: {
    CARE_PLAN_PUBLISHED: "Your Care Plan has been published.",
    GOAL_ADDED: "A new goal {title} has been added to your Care Plan.",
    GOAL_UPDATED: "Your goal {title} has been updated.",
    INTRODUCTION: "Welcome! You will be notified of updates to your Care Plan here. Click me to mark as read.",
    TASK_COMPLETED: "Your task {title} is ready."
  },
  markAsRead: "Mark all as read"
};

export default translations;
