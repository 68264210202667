const translations = {
  body: "Your Care Plan is on the way! For now, take a moment to join Gather, explore media, or register for an event.",
  cta: {
    community: "Join the community",
    events: "Upcoming events",
    library: "Explore the library"
  },
  title: "We've got it from here"
};

export default translations;
