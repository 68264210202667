const menus = {
  admin: {
    admins: "Admins",
    articles: "Articles",
    automations: "Automations",
    carePlanTaskTemplates: "Task templates",
    community: "Community",
    dashboard: "Dashboard",
    demo: "Demo",
    eligibilities: "Eligibility",
    emailMessages: "Emails",
    employers: "Employers",
    expertOOO: "CP Out of office",
    experts: "Care Partners",
    goalList: "Goals",
    internalNoteTemplates: "Internal note templates",
    journeys: "Journeys",
    messageTemplates: "Message templates",
    messages: "Messages",
    myStories: "MyStories",
    oidcProviders: "SSO",
    peerSupportRequests: "Peer support",
    sso: "SSO",
    tags: "Tags",
    timesheets: "Timesheets",
    webinars: "Live events",
    worksheetTemplates: "Worksheets"
  },
  client: {
    account: "Account",
    admin: "Admin",
    care: "Care Plan",
    community: "Community",
    contactUs: "Contact us",
    cookiePolicy: "Cookie policy",
    drive: "Drive",
    faq: "FAQ",
    gather: "Gather",
    getInTouch: "Get in touch",
    home: "Home",
    inviteOthers: "Invite others",
    library: "Library",
    lovedOnesProfile: "LO profile",
    marketplace: "Benefits hub",
    message: "Message",
    plan: "Plan",
    privacy: "Privacy",
    profile: "{name}'s profile",
    schedule: "Schedule",
    signOut: "Sign out",
    termsOfUse: "Terms of use",
    yourProfile: "Your profile"
  },
  copyright: "Copyright {year} © Grayce, Inc. All Rights Reserved.",
  expert: {
    activity: "Activity",
    admin: "Admin",
    ai: "AI",
    allActivities: "All activities",
    allCarePlans: "All Care Plans",
    allConsults: "All sessions",
    allJourneyList: "All journeys",
    allTasks: "All tasks",
    automations: "Automations",
    carePlanTaskTemplates: "Task templates",
    carePlans: "Care Plans",
    consults: "Session",
    dashboard: "Dashboard",
    employers: "Employers",
    expertList: "Care Partner list",
    gather: "Gather",
    goalList: "Goals",
    internalNoteTemplates: "Internal note templates",
    journeyList: "Journey list",
    manageTags: "All tags",
    messageList: "Messages",
    messageTemplates: "Message templates",
    outOfOffice: "Out of office",
    resourceFeatureTemplates: "Resource feature templates",
    search: "Search",
    signOut: "Sign out",
    tags: "Tags",
    tasks: "Tasks",
    timeLogs: "Time tracking"
  },
  expertJourney: {
    account: "Member account",
    accountOwner: "Account owner",
    activity: "Activity",
    additionalParticipants: "Additional participants",
    allConsults: "All sessions",
    articles: "Articles",
    careJourney: "Care journey",
    carePlan: "Plan",
    carePlanningPlus: "Care planning +",
    careProfile: "Profile",
    community: "Community",
    consults: "Session",
    documents: "Documents",
    earliestExpiration: "Earliest expiration",
    employer: "Employer",
    employerUnknown: "Unknown",
    expertOwner: "Care Partner owner",
    explore: "Explore",
    goals: "Goals",
    home: "Home",
    hourBalance: "Hour balance",
    internalNotes: "Internal notes",
    inviteExpert: "Invite Care Partner",
    inviteSupporting: "Invite a supporting Care Partner to join this journey",
    joinJourney: "Join journey",
    learn: "Library",
    leaveJourney: "Leave journey",
    lovedOne: "Loved one",
    lovedOnesProfile: "LO profile",
    marketplace: "Benefits hub",
    medications: "Medications",
    message: "Messages",
    nextPossibleRefresh: "Next refresh",
    profile: "{name}'s profile",
    reallyDeleteExpert: "Are you sure you want to remove {name} from this journey?",
    refreshAvailableNow: "The member is eligible for a refresh now. With activity in the app they would receive ",
    refreshPendingWorkEmailVerification:
      "The member has a package refresh that is pending work email verification. By verifying their email they would receive ",
    sessions: "Sessions",
    supportingExperts: "Supporting Care Partners",
    timeLogs: "Time log"
  }
};

export default menus;
